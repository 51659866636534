import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

import { Theme } from "../../../../types/customTheme";
import CardCommon from "../../../../components/card/CardCommon";
import { convertDateTimeFormat } from "../../../../utils/ConvertDateTimeFormat";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridField: {
      color: theme.palette.custom.orange.contrastText,
      display: "flex",
      justifyContent: "center",
      placeItems: "center",
      fontSize: "12px",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        justifyContent: "center",
        marginTop: "6px",
        textAlign: "center",
      },
    },
    gridFieldLastColumn: {
      color: theme.palette.custom.orange.contrastText,

      placeItems: "center",
      fontSize: "12px",
      paddingRight: "24px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        justifyContent: "center",
        marginTop: "6px",
        paddingRight: "0px",
        marginBottom: "12px",
      },
    },
    gridFieldFirstColumn: {
      color: theme.palette.custom.orange.contrastText,
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      fontSize: "12px",
      paddingLeft: "24px",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
        fontSize: "14px",
        marginTop: "12px",
        justifyContent: "center",
        textAlign: "center",
      },
    },
    root: {
      minHeight: "48px",
      width: "100%",
      margin: "0px",
      padding: "0px",
      color: "white",
    },
  }),
);

export interface VoidTransactionInfoNodeProps {
  nodeData: any;
  locationDetailsList: any;
  userList: any;
  handleOpenVoidDetailsModal: any;
}

/**
 * VoidTransactionInfoNode Component:
 *
 * This component represents a single node in the list of void transactions.
 * It displays information such as user name, location, date, and amount for a void transaction.
 */
const VoidTransactionInfoNode: React.FunctionComponent<
  VoidTransactionInfoNodeProps
> = ({
  nodeData,
  locationDetailsList,
  userList,
  handleOpenVoidDetailsModal,
}) => {
  const [locationName, setLocationName] = useState("");
  const [extraInfo, setExtraInfo] = useState<any>([]);

  /* Set the location name using location id. */
  useEffect(() => {
    const { locationId, extraInfo } = nodeData;
    setExtraInfo(JSON.parse(extraInfo));
    if(JSON.parse(extraInfo)?.type && JSON.parse(extraInfo)?.type !== "void-order") {
      console.log("sdddddddddddddddfgdgfdgfg", nodeData)

    }
    const locationDetails = getFilterListFromArrayObject(
      locationDetailsList,
      locationId,
    );

    // Only if there is a detail of the location, A location name entered into a state.
    if (!_.isEmpty(locationDetails)) {
      const { label } = locationDetails[0];
      setLocationName(label);
    } else {
      // If there is no location name, the state will be changed to 'unknown location'.
      setLocationName("Unknown location");
    }
  }, [nodeData, locationDetailsList]);
  console.log("extraInfo", extraInfo);
  /* Set the user name using user id. */
  const getUserName = (userId: any) => {
    // const user = getFilterListFromArrayObject(userList, userId);
    if (_.isEmpty(userList)) {
      return userId;
    } else if (_.isEmpty(userList[userId])) {
      return userId;
    } else {
      return userList[userId].label;
    }
    // if (!_.isEmpty(userList[userId])) {
    //   return userList[userId].label;
    // } else {
    //   // If there is no username, the state will be changed to 'unknown user'.
    //   return userId;
    // }
  };

  const classes = useStyles();

  return (
    <>
      <CardCommon backgroundColor={"entity_background"}>
        <Button
          className={classes.root}
          onClick={() => handleOpenVoidDetailsModal(extraInfo, nodeData)}
        >
          <Grid container>
            <Grid item xs={12} md={3} className={classes.gridFieldFirstColumn}>
              {getUserName(nodeData.userId)}
            </Grid>
            <Grid
              style={{ textTransform: "capitalize" }}
              item
              xs={12}
              md={3}
              className={classes.gridField}
            >
              {locationName}
            </Grid>
            <Grid item xs={12} md={2} className={classes.gridField}>
              <div style={{ display: "flex", textTransform: "none" }}>
                {convertDateTimeFormat(nodeData.date).slice(
                  0,
                  convertDateTimeFormat(nodeData.date).length - 5,
                )}
                <div style={{ fontWeight: "bold" }}>
                  {convertDateTimeFormat(nodeData.date).slice(-5)}
                </div>
              </div>
              {/* {convertDateTimeFormat(nodeData.date)} */}
            </Grid>
            <Grid item xs={12} md={2} className={classes.gridField}>
              {parseFloat(nodeData.amount).toFixed(2)}
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              className={classes.gridFieldLastColumn}
              style={{
                display: "flex",
                justifyContent: "end",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography
                style={{
                  flexGrow: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                align="right"
              >
                {extraInfo?.comments}
              </Typography>
              {/* {extraInfo?.data?.comments} */}
            </Grid>
          </Grid>
        </Button>
      </CardCommon>
    </>
  );
};

export default VoidTransactionInfoNode;
