import React from "react";
import _ from "lodash";
import {
  Button,
  InputAdornment,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

import { CustomTheme } from "../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      backgroundColor: theme.palette.background.entity_background,
      [`& fieldset`]: {
        borderRadius: "10px",
        border: `1px solid ${theme.palette.background.entity_border}`,
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.background.entity_border}`,
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.background.entity_border}`,
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        height: "16px",
      },
      '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
        {
          "-webkit-appearance": "none",
          margin: 0,
        },
    },
    buttonStyle: {
      backgroundColor: theme.palette.custom.blue.main,
      width: "0px",
      minWidth: "40px",

      "&:hover": {
        backgroundColor: theme.palette.custom.blue.light,
      },
      color: "white",
      height: "36px",
      padding: "0px",
      marginTop: "2px",
    },
    buttonStyleEmpty: {
      width: "0px",
      minWidth: "48px",
      backgroundColor: theme.palette.custom.blue.main,
      "&:hover": {
        backgroundColor: theme.palette.custom.blue.light,
      },
      color: "white",
      height: "36px",
      padding: "0px",
      marginTop: "2px",
    },
  }),
);

export interface IncrementDecrementTextboxProps {
  handleValue: any;
  value: any;
  handleIncrement: any;
  handleDecrement: any;
  isDisable?: any;
  displaySuffix: any;
}

/**
 * This component provides an input field with increment and decrement buttons for adjusting quantity values.
 * It is commonly used in various parts of the application to allow users to modify quantities easily.
 */
const IncrementDecrementTextboxNormal: React.FunctionComponent<
  IncrementDecrementTextboxProps
> = ({
  handleValue,
  value,
  handleIncrement,
  handleDecrement,
  isDisable,
  displaySuffix,
}) => {
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Button
        className={
          value !== "" ? classes.buttonStyle : classes.buttonStyleEmpty
        }
        size="small"
        disabled={isDisable}
        onClick={() => handleDecrement()}
      >
        <RemoveIcon
          style={{
            fontSize: "16px",
          }}
        />
      </Button>
      <TextField
        id="value"
        name="value"
        label=""
        type="number"
        style={{
          marginLeft: "8px",
          marginRight: "8px",
        }}
        variant="outlined"
        className={classes.textField}
        margin="dense"
        disabled={isDisable}
        value={value}
        onChange={handleValue}
        InputProps={{
          endAdornment: (
            <>
              <InputAdornment position="end">
                <Typography style={{ fontSize: "12px" }}>
                  {displaySuffix}
                </Typography>
              </InputAdornment>
            </>
          ),
          style: { fontSize: "24px" },
        }}
      />

      <Button
        className={
          value !== "" ? classes.buttonStyle : classes.buttonStyleEmpty
        }
        disabled={isDisable}
        onClick={() => handleIncrement()}
        size="small"
      >
        <AddIcon
          style={{
            fontSize: "16px",
          }}
        />
      </Button>
    </div>
  );
};

export default IncrementDecrementTextboxNormal;
