import {
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CardCommon from "../../../../components/card/CardCommon";
import { CustomTheme } from "../../../../types/customTheme";
import _ from "lodash";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import { convertDateTimeFormat } from "../../../../utils/ConvertDateTimeFormat";

export interface VoidLineProps {
  extraInfo: any;
  nodeData: any;
  userList: any;
  locationDetailsList: any;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  headerFirstItemStyle: {
    paddingLeft: "12px",
  },
  headerStyle: {
    display: "flex",
    justifyContent: "center",
  },
  headerLastItemStyle: {
    display: "flex",
    justifyContent: "end",
    paddingRight: "12px",
  },
}));

const VoidLine: React.FC<VoidLineProps> = ({
  extraInfo,
  nodeData,
  userList,
  locationDetailsList,
}) => {
  const [locationName, setLocationName] = useState("");
  const getUserName = (userId: any) => {
    // const user = getFilterListFromArrayObject(userList, userId);
    if (_.isEmpty(userList)) {
      return userId;
    } else if (_.isEmpty(userList[userId])) {
      return userId;
    } else {
      return userList[userId].label;
    }
    // if (!_.isEmpty(userList[userId])) {
    //   return userList[userId].label;
    // } else {
    //   // If there is no username, the state will be changed to 'unknown user'.
    //   return userId;
    // }
  };
  useEffect(() => {
    const locationDetails = getFilterListFromArrayObject(
      locationDetailsList,
      nodeData.locationId,
    );

    // Only if there is a detail of the location, A location name entered into a state.
    if (!_.isEmpty(locationDetails)) {
      const { label } = locationDetails[0];
      setLocationName(label);
    } else {
      // If there is no location name, the state will be changed to 'unknown location'.
      setLocationName("Unknown location");
    }
  }, []);

  const classes = useStyles();
  console.log("extraI54nfo", extraInfo);
  return (
    <>
      <DialogTitle>Void Line</DialogTitle>
      <DialogContent>
        <CardCommon backgroundColor={"table_header_background"}>
          <Grid container style={{ height: "40px", placeItems: "center" }}>
            <Grid item xs={4} className={classes.headerFirstItemStyle}>
              <Typography>Item Name</Typography>
            </Grid>
            <Grid item xs={4} className={classes.headerStyle}>
              <Typography>Quantity</Typography>
            </Grid>
            <Grid item xs={4} className={classes.headerLastItemStyle}>
              <Typography>Price</Typography>
            </Grid>
          </Grid>
        </CardCommon>
        <div style={{ marginTop: "4px" }}>
          <CardCommon backgroundColor={"entity_highlight_background"}>
            <Grid container style={{ height: "40px", placeItems: "center" }}>
              <Grid item xs={4} className={classes.headerFirstItemStyle}>
                <Typography>{extraInfo.data.itemName}</Typography>
              </Grid>
              <Grid item xs={4} className={classes.headerStyle}>
                <Typography>{extraInfo.data.qty}</Typography>
              </Grid>
              <Grid item xs={4} className={classes.headerLastItemStyle}>
                <Typography>
                  {parseFloat(extraInfo.data.amount).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </CardCommon>
        </div>
        <div style={{ marginTop: "40px" }}>
          <Grid container>
            <Grid item xs={6}>
              <CardCommon backgroundColor={"entity_background"}>
                <div style={{ display: "flex" }}>
                  <Typography
                    style={{
                      paddingLeft: "24px",
                      paddingTop: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    User :
                  </Typography>
                  <Typography
                    style={{ paddingLeft: "56px", paddingTop: "20px" }}
                  >
                    {getUserName(nodeData.userId)}
                  </Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Typography
                    style={{
                      paddingLeft: "24px",
                      paddingTop: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    Location :
                  </Typography>
                  <Typography
                    style={{ paddingLeft: "28px", paddingTop: "12px" }}
                  >
                    {locationName}
                  </Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Typography
                    style={{
                      paddingLeft: "24px",
                      paddingTop: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    Date :
                  </Typography>
                  <Typography
                    style={{ paddingLeft: "55px", paddingTop: "12px" }}
                  >
                    <div style={{ display: "flex", textTransform: "none" }}>
                      {convertDateTimeFormat(nodeData.date).slice(
                        0,
                        convertDateTimeFormat(nodeData.date).length - 5,
                      )}
                      <div style={{ fontWeight: "bold" }}>
                        {convertDateTimeFormat(nodeData.date).slice(-5)}
                      </div>
                    </div>
                  </Typography>
                </div>
                <div style={{ display: "flex", paddingBottom: extraInfo?.comments?"0px":"20px" }}>
                  <Typography
                    style={{
                      paddingLeft: "24px",
                      paddingTop: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    Amount :
                  </Typography>
                  <Typography
                    style={{ paddingLeft: "30px", paddingTop: "12px" }}
                  >
                    {parseFloat(nodeData.amount).toFixed(2)}
                  </Typography>
                </div>
                {extraInfo?.comments && (
                  <div style={{ display: "flex", paddingBottom: "20px" }}>
                    <Typography
                      style={{
                        paddingLeft: "24px",
                        paddingTop: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Comments :
                    </Typography>
                    <Typography
                      style={{ paddingLeft: "12px", paddingTop: "12px" }}
                    >
                      {extraInfo?.comments}
                    </Typography>
                  </div>
                )}
              </CardCommon>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </>
  );
};

export default VoidLine;
