import React from "react";
import _ from "lodash";
import { DialogContent, DialogTitle } from "@material-ui/core";

export interface VoidLineProps {
    extraInfo: any
}

const VoidOut: React.FC<
  VoidLineProps
> = ({ extraInfo }) => {
  return (
    <>
      <DialogTitle>Void Out</DialogTitle>
      <DialogContent>
        
      </DialogContent>
    </>
  );
};

export default VoidOut;
