import { Button, Grid, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import { CustomTheme, Theme } from "../../../../types/customTheme";
import CardCommon from "../../../../components/card/CardCommon";
import { convertDateTimeFormatInventory } from "../../../../utils/ConvertDateTimeFormat";
import StockLevelRow from "../../../../components/common/StockLevelRow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridField: {
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: "11px",
      placeItems: "center",
      fontSize: "13px",
      minHeight: "36px",
      textTransform: "none",
      color: theme.palette.custom.orange.contrastText,
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        justifyContent: "center",
        marginTop: "6px",
        paddingLeft: "0px",
      },
    },
    gridFieldLastColumn: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9px",
      marginTop: "2px",
      marginBottom: "8px",
      placeItems: "center",
      fontSize: "13px",
      minHeight: "36px",
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "0px",
        fontSize: "14px",
        justifyContent: "center",
      },
    },
    gridFieldFirstColumn: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      fontSize: "13px",
      paddingLeft: "16px",
      minHeight: "36px",
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
        fontSize: "14px",
        marginTop: "12px",
        justifyContent: "center",
      },
    },
  }),
);

export interface StockItemInfoNodeProps {
  nodeData: any;
  locationSelectorList: any;
  handleOpenStockLevelModal: any;
  departmentName: any;
  stockLevelObj: any;
  group: any;
}

/* This component creates the data for the table using the data received from the API call.  */
const StockItemInfoNode: React.FunctionComponent<StockItemInfoNodeProps> = ({
  nodeData,
  locationSelectorList,
  handleOpenStockLevelModal,
  departmentName,
  stockLevelObj,
  group,
}) => {
  const [name, setName] = useState("");
  const [displaySuffix, setDisplaySuffix] = useState("");
  const [inputMultiplier, setInputMultiplier] = useState("");
  const [inputSuffix, setInputSuffix] = useState("");
  const [stockId, setStockId] = useState("");
  const [groupName, setGroupName] = useState("");

  /* Update states after getting the stock item information. */
  const setNodeDataToState = useCallback((nodeValues) => {
    const { id, inputMultiplier, name, displaySuffix, inputSuffix } =
      nodeValues;
    setGroupName(group.name);
    setStockId(id);
    setDisplaySuffix(displaySuffix);
    setInputSuffix(inputSuffix);
    setInputMultiplier(inputMultiplier);
    setName(name);
  }, []);

  useEffect(() => {
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  const classes = useStyles();
  const theme: CustomTheme = useTheme();
  return (
    <>
      <CardCommon backgroundColor={"entity_background"}>
        <Button
          style={{ width: "100%", margin: "0px", padding: "0px" }}
          onClick={(event: any) =>
            handleOpenStockLevelModal(
              stockId,
              stockLevelObj[stockId]
                ? stockLevelObj[stockId]["data"]["stockLevel"]
                : 0,
              stockLevelObj[stockId]
                ? stockLevelObj[stockId]["data"]["version"]
                : 0,
              name,
              stockLevelObj[stockId] ? stockLevelObj[stockId]["data"]["id"] : 0,
              displaySuffix,
              inputMultiplier,
              inputSuffix,
              event,
            )
          }
        >
          <Grid container>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={2}
              className={classes.gridFieldFirstColumn}
            >
              {groupName}
            </Grid>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={2}
              className={classes.gridField}
            >
              {departmentName.name}
            </Grid>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={3}
              className={classes.gridField}
            >
              {name}
            </Grid>
            <Grid
              style={{
                textAlign: "left",
              }}
              item
              xs={12}
              md={2}
              className={classes.gridField}
            >
              <StockLevelRow
                autoUnitConvert={nodeData.autoUnitConvert}
                showRemainderSep={nodeData.showRemainderSep}
                displaySuffix={nodeData.displaySuffix}
                inputMultiplier={nodeData.inputMultiplier}
                inputSuffix={nodeData.inputSuffix}
                stockLevel={
                  stockLevelObj[stockId]
                    ? stockLevelObj[stockId]["data"]["stockLevel"]
                    : "0"
                }
              />
              {/* <StockLevelRow
                autoUnitConvert={true}
                showRemainderSep={true}
                displaySuffix={"g"}
                inputMultiplier={100}
                inputSuffix={"kg"}
                stockLevel={10234.2343213}
              /> */}
              {/* <div
                style={{
                  marginRight: "8px",
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginBottom: "1px",
                }}
              >
                <div style={{ color: theme.palette.secondary.main }}>
                  {stockLevelObj[stockId]
                    ? stockLevelObj[stockId]["data"]["stockLevel"]
                    : 0}
                </div>
              </div>

              {displaySuffix ? <div>{displaySuffix} </div> : ""} */}
            </Grid>

            <Grid item xs={12} md={3} className={classes.gridFieldLastColumn}>
              {stockLevelObj[stockId]
                ? convertDateTimeFormatInventory(
                    stockLevelObj[stockId]["data"]["updated"],
                  )
                : "-"}
            </Grid>
          </Grid>
        </Button>
      </CardCommon>
      {/* {openOrderDetailsCard && (
        <StockItemDetails
          nodeData={nodeData}
          locationName={locationName}
          updateTime={updateTime}
          createdTime={createdTime}
          handleOpenStockLevelModal={handleOpenStockLevel}
        />
      )} */}
    </>
  );
};

export default withAuthority(StockItemInfoNode, Authorities.INVENTORY_READ);
